<template>
  <div class="discount-coupon">
    <div class="inner-content">
      <div class="create-coupon-bag">
        <button class="create-bag-button" @click="createBag">
          <i class="iconfont">&#xe6e3;</i>
          <span class="bag-word">创建优惠券</span>
        </button>
      </div>
      <div class="coupon-list">
        <el-table
          :data="tableData"
          :header-cell-style="{
            height: '60px',
            color: '#fff',
            background: '#0C0E3F',
            fontSize: '14px',
            fontWeight: '400',
            border: '1px solid transparent',
          }"
          :cell-style="{
            height: '80px',
            color: '#fff',
            background: '#070932',
            fontSize: '14px',
            fontWeight: '400',
          }"
        >
          <el-table-column prop="bag_name" label="商品信息" width="200">
            <template slot-scope="scope">
              <div class="goods-wrapper">
                <div class="goods-cover" v-if="tableData != ''">
                  <img :src="scope.row.goods_master_img" alt="" />
                </div>
                <div
                  class="text-overflow-2 goods-name"
                  :title="scope.row.goods_name"
                >
                  <span>{{ scope.row.goods_name }}</span
                  ><br /><span style="color: #9c9ca5">{{
                    scope.row.goods_sku
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="coupon_name"
            label="优惠券名称"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="coupon_type"
            label="优惠券类型"
            width="180"
          ></el-table-column>
          <el-table-column prop="grant_count" label="发放量"></el-table-column>
          <el-table-column
            prop="everyone_count"
            label="每人限领"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <div class="link-list">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="editCouponBag(scope.row)"
                  >编辑</el-link
                >
                <el-link
                  type="danger"
                  :underline="false"
                  @click="deleteCouponBag(scope.row)"
                  >删除</el-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { couponList, delCoupon } from "@/utils/apis";
export default {
  name: "DiscountCoupon",
  data() {
    return {
      tableData: [],
    };
  },
  computed: {},
  components: {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        paging: 0,
      };
      couponList(params)
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].coupon_type == 1) {
                this.tableData[i].coupon_type = "指定商品满减券";
              } else {
                this.tableData[i].coupon_type = "指定商品折扣券";
              }
            }
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    createBag() {
      this.$router.push({
        path: "/liveMarketing/addCoupon",
        name: "addCoupon",
      });
    },
    editCouponBag(row) {
      this.$router.push({
        path: "/liveMarketing/addCoupon",
        query: { coupon_student_id: row.coupon_student_id },
      });
    },
    deleteCouponBag(row) {
      this.$confirm("是否确定删除该优惠券，删除后将无法恢复", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: "msgBoxClass",
        type: 'warning'
      })
        .then(() => {
          let params = {
            coupon_goods_student_id: row.coupon_goods_student_id,
          };
          delCoupon(params)
            .then((res) => {
              if (res.code == 200) {
                      this.$message.success("删除成功");
                this.getList()
              }
            })
            .catch((err) => {
              console.error("err", err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.msgBoxClass {
  background-color: #0c0e3f !important;
  border: 1px solid #0c0e3f !important;
  p,
  .el-message-box__title {
    color: #ffffff;
  }
  .el-icon-close:before {
    color: #ffffff;
  }
  .el-message-box__content {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .el-message-box__btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 80px;
      height: 36px;
    }
  }
  .el-message-box__btns button:nth-child(1) {
    border: 1px solid #262963 !important;
    border-color: #262963 !important;
    color: #d3d3d3;
    background-color: transparent !important;
  }
}
</style>

<style scoped lang="scss">
.discount-coupon {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.inner-content {
  margin: 20px;
  flex: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
  position: relative;
}

::v-deep .coupon-list .el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #262963;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #101341;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #101341;
  }
}
.create-coupon-bag {
  width: 178px;
  height: 94px;

  .create-bag-button {
    width: 178px;
    height: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    background: url("../../../../assets/image/studentCoupon/studentCoupon.png");
    cursor: pointer;
  }
  .iconfont {
    display: block;
    width: 40px;
    height: 39px;
    margin: 10px 0 8px 60px;
    font-size: 36px;
  }
}
.coupon-list {
  height: 1%;
  flex: 1;
  margin-top: 22px;
  .goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
      width: 50px;
      height: 50px;
      display: flex;
      background: #f6f6f6;
      border-radius: 8px;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
    .goods-name {
      width: 1%;
      flex: 1;
      margin-left: 10px;
      font-size: 14px;
    }
  }
}
.el-table {
  position: absolute;
  width: 100%;

  &:before {
    content: none;
  }

  ::v-deep.el-table__empty-block {
    background: #070932;

    .el-table__empty-text {
      color: #fff;
    }
  }

  ::v-deep .el-table__body-wrapper {
    background: #070932;
  }

  ::v-deep th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  ::v-deep td.el-table__cell {
    border-bottom: 1px solid #262963;

    &:first-child {
      border-left: 1px solid #262963;
    }

    &:last-child {
      border-right: 1px solid #262963;
    }
  }
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #0e0c3f !important;
}
</style>
